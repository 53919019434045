<template>
  <b-badge :variant="buildStatus.type">{{ buildStatus.text }}</b-badge>
</template>

<script>
export default {
  name: "PedidoStatus",
  props: {
    id: {
      type: String,
      default: "PedidoStatus"
    },
    name: {
      type: String,
      default: "PedidoStatus"
    },
    status: {
      type: String,
      default: ""
    }
  },
  computed: {
    buildStatus() {
      let status = {
        text: "",
        type: ""
      };
      switch (this.$props.status) {
        case "C":
          status.text = "Pedido Confirmado";
          status.type = "primary";
          break;

        case "D":
          status.text = "Mercadoria Despachada";
          status.type = "primary";
          break;

        case "E":
          status.text = "Aguardando";
          status.type = "warning";
          break;

        case "I":
          status.text = "Pedido importado";
          status.type = "light";
          break;

        case "F":
          status.text = "Faturado";
          status.type = "success";
          break;
        
        case "L":
          status.text = "Cancelado";
          status.type = "danger";
          break;

        default:
          status.text = "Indefinido(" + this.$props.status + ")";
          status.type = "danger";
          break;
      }
      return status;
    }
  }
};
</script>
