<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <span class="col-sm-12 col-md-2">
          <i class="icon-list"></i> Lista de Pedidos
        </span>
      </b-card-header>
      <b-card-body>
        <b-container>
          <i
            style="display: none"
            @click="reload"
            class="fa fa-times fa-lg icone-x"
            id="icone-x"
          ></i>

          <b-form-checkbox
            id="exibir-cancelados"
            name="exibir-cancelados"
            v-model="exibirCancelados"
            unchecked-value="false"
            @change="filtarExibirCancelados()"
          >
            <span>Exibir pedidos cancelados</span>
          </b-form-checkbox>

          <my-data-table
            id="ordersTable"
            :search="this.searchResult.search"
            :fields="this.searchResult.fields"
            :hiddenFields="hiddenFields"
            :sortColumn="this.searchResult.sortColumn"
            :sortDirection="this.searchResult.sortDirection"
            :totalResults="this.searchResult.totalResults"
            :results="this.searchResult.results"
            :resultsPerPage="this.searchResult.resultsPerPage"
            :totalPages="this.searchResult.totalPages"
            :page="this.searchResult.page"
            :pages="this.searchResult.pages"
            :buttons="'end'"
            class="font-12"
            v-on:doSearch="loadOrders"
            :showResultsPerPage="false"
          >
            <template v-slot:default="value">{{
              value.propertyValue | format(value.propertyName)
            }}</template>

            <template v-slot:status="value">
              <div class="text-center">
                <pedido-status :status="value.propertyValue" />
              </div>
            </template>

            <template v-slot:cd_filial="value">
              <div class="text-center">
                <span :class="badgeFilial(value.propertyValue)">{{
                  getFilial(value.propertyValue)
                }}</span>
              </div>
            </template>

            <template v-slot:total="value">
              <div class="w-100 text-right moeda-real">
                {{ value.propertyValue | money }}
              </div>
            </template>

            <template #buttons="value">
              <div class="text-center">
                <b-button
                  class="my-data-table-row-button"
                  size="sm"
                  variant="success"
                  v-on:click="openOrder(value.row)"
                >
                  <i class="fa fa-search-plus"></i>
                </b-button>
              </div>
            </template>
            <template #buttonsCaption>Ações</template>
          </my-data-table>
        </b-container>
      </b-card-body>
    </b-card>
  </div>
</template>
<style>
.font-12 {
  font-size: 12px;
}
#codigoordersTable {
  width: 9% !important;
}
#data_pedidoordersTable {
  width: 11% !important;
}
#cliente_codigoordersTable {
  width: 11% !important;
}
#razao_socialordersTable {
  width: 27% !important;
}
#totalordersTable {
  width: 8% !important;
}
#statusordersTable {
  width: 6% !important;
}
#button {
  width: 4% !important;
}
#cd_filialordersTable {
  width: 7% !important;
}
</style>

<style scoped>
.badge-itj {
  color: #fff;
  background-color: #ff6d13;
  width: 39px;
}

.badge-mga {
  color: #fff;
  background-color: #0075eb;
}

.icone-x {
  position: absolute;
  margin-top: 0.7em;
  margin-left: 56em;
  z-index: 10;
  cursor: pointer;
}
@media screen and (max-width: 1460px) {
  .icone-x {
    margin-left: 55em;
  }
}

@media screen and (max-width: 1420px) {
  .icone-x {
    margin-left: 54em;
  }
}

@media screen and (max-width: 1400px) {
  .icone-x {
    margin-left: 53em;
  }
}

@media screen and (max-width: 1380px) {
  .icone-x {
    margin-left: 52em;
  }
}

@media screen and (max-width: 1365px) {
  .icone-x {
    margin-left: 51em;
  }
}
</style>
<script>
import Revenda from "../../../services/revenda";
import MyDataTable from "@/components/ui/MyDataTable";
import PedidoStatus from "./PedidoStatus";
import Vue from "vue";

export default {
  name: "Pedidos",
  components: { MyDataTable, PedidoStatus },
  data() {
    return {
      hiddenFields: ["codigo_eive", "total_erp"],
      searchResult: new Object(),
      exibirCancelados: false,
      params: {
        params: {
          resultsPerPage: 20,
          sortColumn: "codigo",
          sortDirection: "desc",
        },
      },
    };
  },
  computed: {},

  beforeMount() {
    this.loadOrders(this.params);
  },
  methods: {
    loadOrders(data = null) {
      let icone = document.getElementById("icone-x");
      let pesquisa = document.getElementById("pesquisa-input");

      if (pesquisa && pesquisa.value != "") {
        icone.style.display = "block";
      } else if (pesquisa) {
        icone.style.display = "none";
      }

      let params = "exibirCancelados=" + this.exibirCancelados;

      Revenda.getPedidos(data, params)
        .then((response) => {
          this.searchResult = response.data.data;
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.status &&
            e.response.status === 404 &&
            e.response.data
          ) {
            this.searchResult = e.response.data.data;
          }
        });
    },
    badgeFilial(cd_filial) {
      return cd_filial == 1 ? "badge badge-mga" : "badge badge-itj";
    },
    getFilial(cd_filial) {
      return cd_filial == 1 ? "Maringá" : "Itajaí";
    },
    openOrder(row) {
      this.$router.push("pedidos/pedido/" + row.codigo);
    },
    reload() {
      location.reload();
    },
    filtarExibirCancelados() {
      if (!this.exibirCancelados)
        this.params.params.page = this.searchResult.page;
      else this.params.params.page = 1;
      this.loadOrders(this.params);
    },
  },
  filters: {
    format(value, field) {
      switch (field) {
        case "razao_social":
          return Vue.filter("toUpper")(value);
        case "data_pedido":
          return Vue.filter("formatDate")(value);
        default:
          return value;
      }
    },
  },
};
</script>
